@import "fonts";
@import "vars";

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &:before, &:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; } }

*::-webkit-input-placeholder {
  color: #666;
  opacity: 1; }

*:-moz-placeholder {
  color: #666;
  opacity: 1; }

*::-moz-placeholder {
  color: #666;
  opacity: 1; }

*:-ms-input-placeholder {
  color: #666;
  opacity: 1; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
  color: #666; }

body input:required:valid,
body textarea:required:valid {
  color: #666; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: normal;
  font-family: $default-font;
  background: #fff; }

html, body {
  overflow-x: hidden; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

.text-center {
  text-align: center; }

a {
  display: block;
  transition: .3s ease;
  text-decoration: none;
  outline: none;
  &:hover {
    text-decoration: none; } }

button {
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s ease;
  cursor: pointer; }

label {
  display: block; }

input {
  background: transparent;
  transition: .3s ease;
  padding: 0 2px 8px;
  box-sizing: border-box;
  outline: none;
  border: none {
    bottom: 1px solid rgba(#333, .5); }
  font-size: 20px;
  font-weight: 100;
  opacity: .7;
  color: #000000;
  &::placeholder {
    color: #000000; } }

input[name='phone'] {
  width: 250px; }

h1 {
  width: 100%;
  text-align: center;
  font: {
    family: 'sgl', sans-serif;
    size: 52px; }
  margin: 0 auto 10px; }

h2 {
  width: 100%;
  text-align: center;
  color: #000000;
  font: {
    family: 'sgl', sans-serif;
    size: 40px; }
  margin: 0 auto; }


.logo {
  width: 128px; }

.list-social {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 0 0 23px;
  box-sizing: border-box;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    width: 19px;
    height: 2px;
    background: #fff; }
  li {
    a {
      margin: 0 4px;
      width: 37px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        opacity: .7; } } } }

.list-check {
  li {
    position: relative;
    font: {
      weight: 500;
      size: 24px; }
    padding: 0 0 0 31px;
    box-sizing: border-box;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0,-50%);
      width: 22px;
      height: 22px;
      background: url("../img/check.png") no-repeat center {
        size: contain; } } } }

.bg-accent {
  background-color: $btn;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px; }

.border-accent {
  border-radius: 15px;
  border: 1px solid $btn;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 3px 19px; }

.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%; }

.btn {
  display: flex;
  align-items: center;
  justify-content: center; }

.btn-white {
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 25px;
  padding: 10px;
  box-sizing: border-box;
  color: #333333;
  width: 236px;
  height: 49px;
  font: {
    family: 'sgb', sans-serif;
    size: 20px; }
  &:hover {
    background-color: #eae9e9; } }

.btn-accent {
  box-shadow: 0 5px 25px 2px rgba(242, 140, 167, 0.45);
  border-radius: 25px;
  background-color: $btn;
  color: #fff;
  width: 236px;
  height: 49px;
  font-size: 20px;
  font-family: 'sgsb', sans-serif;
  &:hover {
    background-color: #e77593; } }


//video
.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: auto; }

//.video-bg
//  position: absolute
//  top: 0
//  right: 0
//  bottom: 0
//  left: 0
//  overflow: hidden
//  z-index: 1
//  > video
//    position: absolute
//    top: 0
//    left: 0
//    min-width: 100%
//    min-height: 100%
//    width: 100%
//    height: auto
//
//
//@supports (-o-object-fit: cover) or (object-fit: cover)
//  .video-bg > video
//    top: 0
//    left: 0
//    width: 100%
//    height: 100%
//    -o-object-fit: cover
//    object-fit: cover


//section main
main {
  position: relative;
  padding: 50px 0 120px;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.7);
    z-index: 2; } }

.main-content {
  position: relative;
  z-index: 2;
  margin: 214px 0 0;
  color: #FFFFFF;
  .container {
    flex-direction: column;
    align-items: center;
    .sale {
      margin: 30px auto 0; }
    p {
      text-align: center;
      font: {
        family: 'sgl', sans-serif;
        size: 25px; } }
    .btn-white {
      margin: 89px auto 222px; }
    .list-check {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between; } } }


header {
  position: relative;
  z-index: 2;
  color: #fff;
  .container {
    justify-content: space-between;
    align-items: center;
    max-width: 1500px;
    .list-social {
      margin: 0 auto 0 76px; } } }

.box-contacts {
  position: relative;
  padding: 0 0 0 61px;
  box-sizing: border-box;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 42px;
    height: 52px;
    background: url('../img/location.png') no-repeat center {
      size: contain; } }
  p {
    font: {
      family: 'sgb', sans-serif;
      size: 20px; } } }

.phone {
  color: #fff;
  font: {
    family: 'sgb', sans-serif;
    size: 20px; }
  &:hover {
    color: $accent1; } }

//section equipment
.equipment {
  padding: 50px 0;
  .container {
    max-width: 1130px; }
  .border-accent {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px auto 0;
    width: 170px;
    height: 36px;
    border-radius: 18px;
    color: #000000;
    font: {
      family: 'sgl', sans-serif;
      size: 20px; } } }

.equipment-content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: 14px 0 0; }

.equipment-video {
  width: 601px;
  height: 479px;
  margin: 0 18px 0 0;
  position: relative;
  overflow: hidden;
  .fullscreen-bg__video {
    width: 107%;
    height: 100%;
    left: -10px;
    margin: 0;
    right: auto; } }


video {
  width: 100%;
  height: 100%;
  outline: none; }

.equipment-text {
  max-width: 470px;
  font-family: 'Open Sans', sans-serif;
  p {
    margin: 0 0 33px;
    font: {
      weight: 100;
      size: 20px; }
    &:last-child {
      margin: 0; } } }

//section box-callback
.box-callback {
  background-image: linear-gradient(72deg, #f9e9ed 0%, #f8eff1 100%);
  width: 100%;
  //padding: 52px 0 0 0
  .container {
    max-width: 1500px;
    justify-content: space-between;
    align-items: center;
    p {
      max-width: 690px;
      color: #333;
      font-weight: 100;
      font-size: 23px;
      font-family: 'sg', sans-serif;
      margin: 0 auto 0 52px;
      line-height: 43px; }
    .image {
      margin: 52px 0 0 0; } } }

.form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  button {
    margin: 0 0 0 57px; } }


//section stages-work
.stages-work {
  padding: 99px 0 65px; }

.stages-work__content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 76px 0 0 0;
  .container {
    max-width: 1170px; } }

.stages-description {
  max-width: 387px;
  font-size: 20px;
  font-weight: 100;
  color: #000000;
  line-height: 34px; }


.stages-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }


.box-stages {
  position: relative;
  width: 121px;
  height: 121px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 5px 25px 2px rgba(237, 188, 201, 0.41);
  .number {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3959c;
    color: #ffffff;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: -15px; }
  &:nth-child(2) {
    margin: 0 113px; } }

//section price
.price {
  background: rgba(#f8e6eb, .89);
  position: relative;
  padding: 80px 0 40px;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 609px;
    height: 478px;
    top: 50%;
    transform: translate(0,-50%);
    background: url("../img/decor1.png") no-repeat center {
      size: contain; }
    z-index: -1; }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1091px;
    height: 939px;
    bottom: -25px;
    background: url("../img/woomen.png") no-repeat center {
      size: contain; }
    opacity: .89; }
  .container {
    z-index: 2;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    > p {
        text-align: center;
        margin: 15px 0 0 0;
        width: 100%;
        font: {
          size: 20px;
          weight: 100; } }
    .btn-accent {
      margin: 0 auto; } } }


.price-table {
  margin: 45px 0 45px;
  width: 790px;

  li {
    width: 100%;

    font: {
      size: 18px;
      weight: 100; }
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 23px;
    background-color: #ffffff;
    margin: 0 0 23px;
    box-sizing: border-box;
    p {
      margin: 0; } }
  .cost {
    min-width: 130px;
    margin: 0 41px 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 100;
    font-family: $default-font;
    p {
      width: auto; } }
  .first-visit {
    min-width: 185px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      width: auto;
      padding: 4px 27px; } }
  .price-table__header {
    padding: 0 12px 0 0;
    color: #333333;
    font-size: 18px;
    font-family: 'sg', sans-serif;

    .popular-zones {

      padding: 10px 35px;
      border-radius: 23px; }
    .cost {
      font: {
        weight: 100;
        size: 18px; } }
    .first-visit {
      padding: 3px 12px; } }
  .price-table__body {
    padding: 7px 12px 7px 31px;
    color: #000;
    font-size: 16px;
    .popular-zones {
      font-size: 18px; }
    .cost {
      p {
        font-weight: 100;
        font-family: $default-font; } }
    .first-visit {
      p {
        font-family: 'sg', sans-serif;
        font-size: 16px; } } } }

//section instruction
.instruction {
  background: #fff;
  padding: 100px 0 0;
  .container {
    max-width: 1210px;
    justify-content: space-between; } }

.list-instruction {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  > li {
      width: 48.5%;
      box-shadow: 0 5px 25px 2px rgba(237, 188, 201, 0.23);
      background: #fff;
      box-sizing: border-box;
      border-radius: 10px;
      margin: 0 0 35px;
      padding: 26px 15px 28px 24px;
      h3.in {
        &:before {
          height: 0; } }
      h3 {
        position: relative;
        width: 100%;
        color: #333333;
        font-size: 23px;
        font-family: 'sg', sans-serif;
        padding: 0 35px 0 0;
        cursor: pointer;
        &:before, &:after {
          content: '';
          position: absolute;
          background-color: $accent1;
          transition: .3s ease;
          top: 0; }
        &:after {
          width: 35px;
          height: 3px;
          right: 0;
          top: 14px; }
        &:before {
          width: 3px;
          height: 35px;
          right: 16px;
          top: -1px; } } }

  .b_hidden {
    padding: 28px 15px 0 0;
    display: none; }
  p {
    font-size: 18px;
    font-weight: 100;
    line-height: 30px;
    margin: 0 0 25px;
    &:last-child {
      margin: 0; } }
  ul {
    li {
      font-size: 18px;
      font-weight: 100;
      line-height: 30px;
      margin: 0 0 10px;
      &:last-child {
        margin: 0; } } }
  strong {
    font-weight: 500; } }

.list-dots {
  list-style: disc;
  padding: 0 0 0 24px;
  li {
    font-size: 18px;
    font-weight: 100;
    line-height: 30px; } }

.list-question {
  padding: 0 0 0 24px;
  li {
    position: relative;
    padding: 0 0 0 30px;
    &:before {
      content: '?';
      position: absolute;
      left: 0;
      border: 1px solid #000;
      box-sizing: border-box;
      padding: 4px;
      width: 20px;
      height: 20px;
      top: 5px;
      font-weight: 500;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center; } } }

//section reviews
.reviews {
  padding: 60px 0 80px;
  background: #fff; }

.reviews-slider {
  width: 100%;
  margin: 72px 0 0 0; }

.reviews-box {
  box-shadow: 0 5px 25px 2px rgba(237, 188, 201, 0.23);
  border-radius: 10px;
  background-color: #faebef;
  box-sizing: border-box;
  margin: 0 auto 20px;
  padding: 25px 25px 34px 25px;
  font-weight: 100;
  width: 350px;
  p {
    font-size: 15px;
    line-height: 26px; }
  &__top {
    display: flex;
    align-items: center;
    margin: 0 0 27px;
    .image {
      width: 82px;
      height: 82px;
      min-width: 82px;
      box-shadow: 7px 7px 25px 2px rgba(237, 211, 217, 0.33);
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      //border: 8px solid #fff
      box-sizing: border-box;
      border-radius: 50%;
      //overflow: hidden
      padding: 8px;
      margin: 0 16px 0 0;
      .it {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        box-shadow: 7px 7px 25px 2px rgba(233, 192, 202, 0.42); }
      .info-user {
        font: {
          size: 18px; } } } } }

.slick-next, .slick-prev {
  width: 22px;
  height: 40px;
  top: 50%;
  z-index: 3;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: url("../img/arrow-right-grey.png") no-repeat center {
      size: contain; }
    position: absolute;
    top: 0; } }

.slick-next {
  right: -31px;
  &:before {
    right: 0; } }

.slick-prev {
  left: -31px;
  &:before {
    left: 0;
    transform: rotate(180deg); } }


//section mobile-attachment
.mobile-attachment {
  background-image: linear-gradient(72deg, #f9e9ed 0%, #f8eff1 100%);
  position: relative;
  padding: 85px 0 0 0;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 450px;
    height: 482px;
    background: url("../img/decor2.png") no-repeat center {
      size: contain; } }
  .container {
    flex-direction: column;
    &:before, &:after {
      content: '';
      position: absolute;
      bottom: 0;
      box-shadow: 20px -5px 25px 2px rgba(192, 191, 191, 0.42);
      border-radius: 30px 30px 0 0; }
    &:before {
      right: 0;
      background: url("../img/phone1.png") no-repeat center {
        size: contain; }
      width: 208px;
      height: 371px;
      z-index: 1; }
    &:after {
      right: -136px;
      background: url("../img/phone2.png") no-repeat center {
        size: contain; }
      width: 192px;
      height: 341px; }
    h2 {
      text-align: left;
      max-width: 820px;
      margin: 0; }
    .btn-accent {
      margin: 0 0 80px; } } }

.list-plus {
  margin: 43px 0;
  li {
    position: relative;
    padding: 0 0 0 37px;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 100;
    margin: 0 0 26px;
    &:last-child {
      margin: 0; }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0,-50%);
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background: url("../img/plus.png") no-repeat center {
        size: contain; } } } }


//section additional-procedures
.additional-procedures {
  background: #ffff;
  padding: 99px 0 0;
  h2 {
    margin: 0 auto 85px; }
  .container {
    max-width: 1430px; } }

.additional-procedures__box {
  max-width: 1240px;
  width: 100%;
  display: flex;
  box-shadow: 7px 7px 25px 2px rgba(229, 229, 229, 0.33);
  background-color: $bg-blue-light;
  border-radius: 10px;
  margin: 0 auto 50px;
  align-items: stretch;
  height: 367px;
  .image {
    height: 100%;
    width: 428px;
    min-width: 428px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px 0 0 10px;
    img {
      height: 100%;
      max-width: none; } }
  .text {
    padding: 19px 80px 25px 22px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #000000;
    font-weight: 100;
    h3 {
      font-size: 25px;
      font-weight: 300; } }
  p {
    font-size: 20px;
    line-height: 30px; }
  .description {
    margin: 23px 0 auto; }
  .btn-accent {
    margin: 0 auto; } }

.slide {
  outline: none; }


//section about
.about {
  padding: 166px 0 104px;
  .border-accent {
    font-size: 30px;
    text-shadow: 0 5px 25px rgba(226, 226, 226, 0.41);
    padding: 8px 30px;
    border-radius: 29px;
    width: fit-content;
    margin: 33px auto 64px;
    font-family: 'sgl', sans-serif; } }

.about__content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap; }

.about-slider {
  width: 50%;
  //max-height: 649px
  .image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      max-width: none; } }
  .slick-arrow {
    top: auto;
    bottom: 28px;

    border: 2px solid #ffffff;
    width: 45px;
    height: 50px;
    box-sizing: border-box;
    &:before {
      background: url("../img/arr-right.png") no-repeat center {
        size: contain; }
      width: 14px;
      height: 25px;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto; } }
  .slick-next {
    right: 52px;
    border-radius: 0 25px 25px 0; }
  .slick-prev {
    left: auto;
    right: 95px;
    border-radius: 25px 0 0 25px;
    &:before {
      transform: rotate(180deg); } } }

.about-description {
  width: 50%;
  padding: 0 67px 0 43px;
  box-sizing: border-box;
  font-size: 23px;
  font-weight: 100;
  line-height: 30px;
  p {
    margin: 40px 0 0 0;
    &:first-child {
      margin: 0; } } }

//section licenses
.licenses {
  background: #fff; }

.licenses-content {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  margin: 56px auto 0; }

.licenses-box {
  margin: 0 25px 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 313px;
  a {
    margin: 21px auto 0;
    text-decoration: underline;
    text-align: center;
    display: block;
    font-size: 15px;
    font-family: 'sg', sans-serif;
    color: #737373; }
  .image {
    width: 100%;
    height: 416px;
    display: flex;
    align-items: center;
    justify-content: center; } }


.cosmetologists {
  background-color: rgba($bg-blue-light, .97);
  padding: 64px 0; }

.cosmetologists-content {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  margin: 80px auto 62px;
  width: 100%;
  max-width: 820px;
  position: relative;
  z-index: 2;
  &:before, &:after {
    content: '';
    position: absolute; }
  &:before {
    background: url("../img/decor3.png") no-repeat center {
      size: contain; }
    width: 246px;
    height: 270px;
    top: -87px;
    left: -102px; }
  &:after {
    width: 332px;
    height: 135px;
    bottom: -62px;
    right: -99px;
    background: url("../img/decor4.png") no-repeat center {
      size: contain; } } }

.cosmetologists-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 25px 2px rgba(237, 188, 201, 0.23);
  border-radius: 10px;
  background: #fff;
  z-index: 2;
  position: relative;
  box-sizing: border-box;
  padding: 34px;
  margin: 0 auto;
  width: 389px;
  .image {
    width: 190px;
    height: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    img {
      transform: scale(2); } }
  h3 {
    text-align: center;
    margin: 20px 0;
    font-size: 20px;
    font-weight: 100; }
  ul {
    li {
      text-align: center;
      font-size: 18px;
      font-weight: 100;
      line-height: 20px; } }
  &:nth-child(2) {
    .image {
      img {
        transform: translate(-24%, 25%) scale(1.5); } } } }


//section question-answer
.question-answer {
  padding: 100px 0 65px;
  background: #ffff;
  h2 {
    margin: 0 0 50px; }
  .list-instruction {
    li {
      h3.in {
        &:after {
          transform: rotate(180deg); } }
      h3 {
        color: #1b1a1a;
        font-size: 22px;
        font: {
          family: $default-font;
          weight: 100; }
        &:before {
          display: none; }
        &:after {
          background: url("../img/arr-bottom.png") no-repeat center {
            size: contain; }
          width: 39px;
          height: 21px;
          top: 50%;
          transform: translate(0,-50%);
          right: 0; } } } } }

//section contacts
.contacts {
  position: relative;
  padding: 79px 0 106px;
  overflow: hidden;
  //background: url("../img/bg1.jpg") no-repeat center
  //  size: cover
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(#323232, .75);
    z-index: 2; }
  .container {
    z-index: 3;
    position: relative;
    color: #fff;
    h2 {
      color: #fff; } } }

.contacts-content {
  margin: 98px 0 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }


.map {
  width: 604px;
  height: 430px; }

iframe {
  width: 100%;
  height: 100%; }

.info-contacts-company {
  h3 {
    font-size: 30px;
    font-weight: 100; }
  .phone {
    font-weight: 100;
    font-size: 25px;
    line-height: 30px;
    margin: 0 0 29px;
    font-family: $default-font; }
  .btn {
    margin: 0;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: #fff;
    padding: 13px 20px;
    border: 1px solid #fff {
      radius: 25px; }
    width: 236px;
    height: 49px;
    &:hover {
      background: rgba(#fff, .3);
      border-color: transparent;
      color: #fff; } } }

.box-schedule {
  margin: 32px 0 38px;
  font-size: 25px;
  font-weight: 100;
  line-height: 30px; }


footer {

  box-sizing: border-box;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  .container {
    max-width: 1920px;
    padding: 36px 100px 62px;
    width: 100%; }
  .logo {
    width: 146px;
    height: 103px;
    margin: 0 0 11px; }
  .list-soc {
    border-top: 1px solid #bebebe;
    padding: 12px 0 0 0;
    width: 100%; } }

.list-soc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  li {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 31px;
      svg {
        display: inline-block;
        width: 100%;
        height: 100%; }
      &:hover {
        opacity: .7; } } } }


.info-secondary {
  position: absolute;
  bottom: 32px;
  right: 27px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end; }

.menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 502px;
  margin: 0 0 0 222px;
  li {
    a {
      font-family: 'Open Sans', sans-serif;
      color: #4a4a4f;
      font-weight: 500;
      font-size: 20px;
      border-bottom: 1px solid transparent;
      &:hover {
        font-weight: 700;
        border-color: #4a4a4f; } } } }

.copyright {
  font-size: 16px;
  color: #000000;
  font-family: 'sgl', sans-serif; }

.politics {
  text-decoration: underline;
  color: #000;
  font-size: 16px;
  font-family: 'sgl', sans-serif;
  margin: 7px 0 9px; }

.web-developer {
  color: #696969;
  font-size: 16px;
  font-family: 'sgl', sans-serif; }

//modal
.modal__div {
  position: fixed;
  width: 500px;
  height: 100%;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  overflow: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: none;
  opacity: 0;
  .modal__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 37px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #1d1d1d {
      radius: 50%; }
    padding: 9px;
    box-sizing: border-box; }
  .modal__wrapper {
    background: #fff;
    width: 100%;
    height: auto;
    overflow: auto;
    margin: 20px auto;
    padding: 40px 50px;
    box-sizing: border-box;
    position: relative;
    border-radius: 10px;
    h2 {
      margin: 0;
      color: #1e1e1e;
      font-size: 44px;
      font-family: 'sgl', sans-serif;
      text-align: center;
      line-height: 50px; } }
  .form {
    margin: 40px 0 auto;
    width: 100%;
    flex-direction: column;
    align-items: center;
    label {
      width: 100%;
      margin: 0 0 28px;
      input {
        width: 100%;
        font-size: 26px;
        color: #333;
        border-color: #4a4a4f; } }
    button {
      margin: 20px auto 17px;
      width: 291px;
      height: 75px;
      border-radius: 38px;
      font-size: 27px;
      font-weight: bold;
      font-family: 'Open Sans', sans-serif; }
    .checkbox {
      margin: 0; } } }

.overlay {
  z-index: 100;
  position: fixed;
  background-color: #000;
  opacity: 0.7;
  -moz-opacity: 0.7;
  filter: alpha(opacity = 70);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  display: none; }

#modal__thanks {
  width: 938px;
  .modal__wrapper {
    padding: 151px 130px;
    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 267px;
      height: 134px;
      background: url("../img/decor-modal.png") no-repeat center {
        size: contain; } }
    .text {
      margin: 20px auto 42px;
      padding: 0 0 45px;
      position: relative;
      text-align: center;
      font-size: 26px;
      font-family: 'sgl', sans-serif;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 1px;
        width: 252px;
        background-color: #333333;
        opacity: .5; } }
    p {
      color: #1e1e1e;
      font-size: 26px;
      font-family: 'sgl', sans-serif;
      text-align: center;
      margin: 0 0 20px; }
    .list-soc {
      justify-content: center;
      li {
        a {
          margin: 0 14px; } } } } }

#modal__price {
  width: 922px;
  .modal__wrapper {
    padding: 37px 65px 32px;
    background-color: #faf1f3;
    h2 {
      font-size: 20px;
      font-weight: 100; }
    .price-table {
      margin: 23px 0 0; }
    .form {
      margin: 4px auto 0;
      max-width: 594px;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      .checkbox {
        margin: 9px 0 0 auto; }
      label {
        margin: 0;
        width: auto;
        input {
          width: 250px;
          font-size: 18px;
          font-weight: 100;
          color: #000000; } }
      button {
        width: 236px;
        height: 49px;
        font-size: 20px;
        font-family: 'sgsb', sans-serif;
        margin: 0; }
      p {
        text-align: center;
        font-size: 18px;
        font-weight: 100;
        margin: 0 0 31px; } } } }



//checkbox
.checkbox {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .checkbox-inp {
    display: none; }
  .checkbox-custom {
    position: relative;
    width: 15px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    border: none {
      radius: 3px; }
    background: #4a4a4f;
    cursor: pointer;
    transition: .3s ease;
    margin: 0 7px 0 0;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 10px;
      height: 7px;
      background: url("../img/checked.png") no-repeat center {
        size: contain; } } }
  .label {
    display: inline-block;
    vertical-align: middle;
    color: #333;
    cursor: pointer;
    font-size: 12px;
    font-family: 'sg', sans-serif;
    text-decoration: underline; }
  .checkbox-inp:checked + .checkbox-custom {
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 10px;
      height: 7px;
      background: transparent; } } }

.slick-dots {
  width: 100%;
  bottom: -10px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  li.slick-active {
    button {
      background-color: #e66287; } }
  li {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    button {
      padding: 0;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #8f8f8f;
      &:before {
        display: none; } } } }


@import "media";
// Всегда в конце
