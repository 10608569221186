@import "vars";

/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1720px) {
  .main-content {
    margin: 150px 0 0 0; }

  .main-content .container .btn-white {
    margin: 80px 0 170px; } }


@media only screen and (max-width : 1530px) {
  .box-callback {
    .container {
      p {
        max-width: 440px;
        font-size: 20px;
        line-height: normal; } } }

  .price {
    padding: 30px 0;
    &:after {
      height: 870px;
      width: 850px;
      -webkit-background-size: cover;
      background-size: cover; } }

  .mobile-attachment:before {
    width: 450px;
    height: 480px;
    top: auto;
    bottom: 0;
    left: -10%; }


  .mobile-attachment .container:before {
    right: 30px; }

  .mobile-attachment .container:after {
    right: -70px; }

  main {
    .fullscreen-bg__video {
      width: auto;
      height: 100%; } } }


@media only screen and (max-width : 1360px) {
  .fullscreen-bg__video {
    width: auto;
    height: 100%; } }

@media only screen and (max-width : 1300px) {
  .reviews-slider {
    padding: 0 30px;
    .slick-prev {
      left: 0; }
    .slick-next {
      right: 0; } }

  .additional-procedures__box {
    .text {
      padding: 19px 22px 25px 22px; }
    .btn-accent {
      font-size: 16px; } }

  .about-description {
    padding: 0 20px;
    font-size: 14px; }

  .contacts {
    padding: 70px 0; }

  .about-description {
    font-size: 16px;
    line-height: 24px;
    p {
      margin: 20px 0 0 0; } } }

@media only screen and (max-width : 1200px) {
  .equipment-video {
    width: 400px;
    height: 370px; }

  .box-callback {
    padding: 16px 0 0;
    .container {
      p {
        font-size: 15px;
        max-width: 300px; }
      .image {
        width: 80px; } } }

  .form button {
    margin: 0 0 0 20px; }

  .stages-work {
    padding: 30px 0; }

  .box-stages {
    width: 100px;
    height: 100px; }

  .price {
    &:after {
      display: none; } }

  .mobile-attachment:before {
    display: none; }

  .mobile-attachment .container:before {
    width: 150px;
    height: 258px; }

  .mobile-attachment .container:after {
    width: 95px;
    height: 170px;
    right: 0; }

  .about {
    padding: 50px 0; }

  .licenses-box {
    width: 250px;
    .image {
      height: auto; } }

  .licenses-content {
    max-width: none;
    margin: 30px auto 0; }

  .info-contacts-company {
    h3, .phone {
      font-size: 20px; } }

  .box-schedule {
    font-size: 20px;
    margin: 20px 0; }

  footer {
    .container {
      padding: 20px 25px; } }

  .info-secondary {
    position: relative;
    width: 100%;
    text-align: center;
    justify-content: center;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center; }

  .additional-procedures__box p {
    font-size: 16px;
    line-height: 24px; } }


/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
  main {
    padding: 50px 0; }

  .main-content {
    margin: 150px 0 0;
    .container {
      .btn-white {
        margin: 39px auto 100px; } } }

  .list-check li {
    font-size: 15px; }

  .equipment-text {
    max-width: 400px;
    p {
      font-size: 16px;
      margin: 0 0 27px; } }

  .equipment-video {
    height: 230px;
    .fullscreen-bg__video {
      height: 110%;
      left: -7px; } }

  .box-callback {
    padding: 16px 0 18px;
    .container {
      .image {
        display: none; }
      button {
        font-size: 14px;
        width: 118px;
        height: 25px; } } }

  input[name='phone'] {
    width: 127px;
    font-size: 14px; }

  .stages-description {
    font-size: 16px;
    line-height: 24px; }

  .stages-work__content {
    margin: 37px 0 0 0;
    justify-content: space-around; }

  .box-stages {
    width: 62px;
    height: 62px;
    .number {
      width: 20px;
      height: 20px;
      font-size: 10px;
      top: -8px; }
    &:nth-child(2) {
      margin: 0 17.5px; } }

  .list-instruction > li h3, .question-answer .list-instruction li h3 {
    font-size: 18px; }

  .additional-procedures {
    padding: 30px 0;
    h2 {
      margin: 0 0 30px; } }

  .additional-procedures__box {
    .text {
      padding: 15px;
      h3 {
        font-size: 20px; } } }

  .about {
    padding: 0 20px 30px;
    box-sizing: border-box; }

  .about__content {
    flex-direction: column;
    align-items: center; }

  .about-slider {
    width: 100%;
    .slick-next, .slick-prev {
      border: none {
        radius: 0; }
      bottom: auto;
      top: 50%; }
    .slick-next {
      right: 0; }
    .slick-prev {
      left: 0; } }

  .about-description {
    width: 100%;
    max-width: none;
    padding: 0;
    margin: 10px 0 0 0;
    font-size: 14px;
    line-height: normal;
    p {
      margin: 12px 0 0 0; } }

  .licenses-box {
    width: 180px;
    margin: 0 auto 30px; }

  .licenses-content {
    justify-content: space-around;
    width: 100%; }

  .contacts-content {
    margin: 50px 0 0; }

  .map {
    width: 400px;
    height: 300px; }

  .menu {
    width: 400px;
    margin: 0 0 0 100px;
    li {
      a {
        font-size: 16px; } } }

  #modal__thanks {
    width: 80%;
    .modal__wrapper {
      padding: 19px 37px;
      z-index: 2;
      &:before {
        display: none; } } }

  #modal__price {
    width: 100%;
    .modal__wrapper {
      padding: 19px 5px 18px; } }

  .additional-procedures__box {
    .description {
      margin: 20px 0 auto; }
    p {
      font-size: 15px; } } }

/* Small Devices, Tablets */
@media only screen and (max-width : 850px) {
  .additional-procedures__box p {
    font-size: 14px;
    line-height: 20px; }

  .equipment-text {
    max-width: none;
    margin: 16px 0 0 0;
    text-align: center; }

  .equipment-video {
    margin: 0;
    width: 232px;
    height: 197px; }

  .price-table {
    width: 100%;
    margin: 16px 0 0 0; }

  .price {
    &:before {
      display: none; }
    .container {
      > p {
        font-size: 12px;
        margin: 14px 0 0 0; } } }

  .list-instruction {
    > li {
        width: 100%;
        padding: 16px;
        margin: 0 0 15px;
        h3 {
          &:before {
            height: 18px;
            width: 2px;
            right: 8.5px;
            top: 7px; }
          &:after {
            width: 18px;
            height: 2px; } } } }

  .question-answer {
    .list-instruction {
      li {
        width: 100%;
        padding: 16px;
        margin: 0 0 15px;
        h3 {
          &:after {
            width: 20px;
            height: 12px; } } } } }

  .question-answer {
    padding: 30px 0 0;
    h2 {
      margin: 0 0 20px; } }

  .instruction {
    padding: 30px 0 0 0; }

  .list-question {
    padding: 0; }

  .list-instruction ul li, .list-instruction p, .list-instruction strong {
    font-size: 14px;
    line-height: 18px; }

  .list-question li:before {
    width: 15px;
    height: 15px;
    font-size: 10px;
    top: 0; }

  .list-question {
    margin: 0 0 5px; }

  .list-instruction p {
    margin: 0 0 10px; }

  .cosmetologists {
    padding: 13px 0; }

  .cosmetologists-content {
    margin: 16px 0 0;
    &:after, &:before {
      display: none; } }

  .cosmetologists-box {
    margin: 0 auto 16px; } }

@media only screen and (max-width : 768px) {
  .equipment {
    padding: 30px 0; }

  .price-table {
    li {
      margin: 0 0 13px; }
    .price-table__header, .price-table__body {
      div, .popular-zones, .first-visit p, .cost {
        font-size: 11px; } }
    .price-table__header {
      .popular-zones {
        padding: 7px; }
      .cost {
        padding: 4px;
        min-width: 75px; }
      .first-visit {
        padding: 4px;
        min-width: 108px; } }
    .price-table__body {
      .cost {
        min-width: 90px; }
      .first-visit {
        min-width: 108px;
        p {
          padding: 3px; } } } }
  .border-accent {
    padding: 3px 8px; }

  .btn-accent {
    font-size: 12px;
    width: 116px;
    height: 24px; }

  .reviews {
    padding: 30px 0; }

  .reviews-slider {
    margin: 30px 0 0 0; }

  .mobile-attachment {
    padding: 30px 0 0 0; }

  .additional-procedures__content {
    width: 90%;
    margin: 0 auto; }

  .additional-procedures__box .image {
    width: 300px;
    min-width: 300px; }

  .licenses-content {
    width: 87%; }

  .licenses-box a {
    margin: 20px auto; }

  .contacts-content {
    flex-direction: column-reverse;
    align-items: center;
    position: relative;
    padding: 13px 0 0 0;
    margin: 15px 0 0;
    h3 {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 0;
      text-align: center;
      width: 100%;
      font-size: 13px; } }

  .info-contacts-company {
    width: 100%;
    display: block;
    .phone {
      margin: 0 0 8px; }
    a {
      display: inline-block;
      float: right;
      width: 80%;
      text-align: right;
      margin: 0; }
    .btn {
      font-size: 12px;
      width: 140px;
      height: 29px;
      padding: 0;
      text-align: center;
      line-height: 29px; } }

  .contacts {
    padding: 15px 0 25px; }


  .box-schedule {
    display: block;
    float: left;
    margin: 0;
    font-size: 12px;
    line-height: 20px; }


  .map {
    width: 277px;
    height: 197px;
    margin: 15px auto 27px; }

  .menu {
    flex-direction: column;
    justify-content: flex-start;
    width: auto;
    margin: 0;
    li {
      a {
        margin: 0 0 5px; } } }

  footer {
    .container {
      justify-content: space-between; }
    .logo {
      width: 100px;
      height: auto;
      margin: 0; }
    .box-left {
      display: flex;
      align-items: center;
      width: 80%; }
    .list-soc {
      padding: 0;
      border: none;
      margin: 0 auto;
      width: auto;
      li {
        a {
          margin: 0 6px; } } } } }



/* Extra Small Devices, Phones */
@media only screen and (max-width : 670px) {
  .logo {
    width: 77px;
    height: 55px; }

  header {
    .container {
      .list-social {
        margin: 0 auto 0 6px; } } }

  .list-social {
    &:before {
      width: 15px; }
    li {
      a {
        width: 29px;
        height: 29px; } } }

  .main-content {
    .container {
      .list-check {
        flex-direction: column;
        text-align: center;
        li {
          margin: 0 0 18px;
          &:last-child {
            margin: 0; } } } } }

  .box-contacts {
    padding: 0 0 0 30px;
    &:before {
      background: url("../img/phone.png") no-repeat center {
        size: contain; }
      width: 24px;
      height: 24px; } }

  .address {
    display: none; }

  .box-callback {
    .container {
      p {
        max-width: none;
        margin: 0 0 10px; }
      .form {
        margin: 0 auto; } } }

  .stages-description {
    max-width: 232px;
    font-size: 14px;
    text-align: center; }

  .stages-work__content {
    flex-direction: column-reverse; }

  .stages-wrapper {
    margin: 0 0 28px; }

  .slick-next, .slick-prev {
    width: 12px;
    height: 21px; }

  .list-plus {
    margin: 22px 0 37px;
    li {
      font-size: 13px;
      margin: 0 0 18px;
      padding: 0 0 0 24px;
      &:before {
        width: 17px;
        height: 17px; } } }

  .mobile-attachment {
    .container {
      h2 {
        max-width: none;
        font-size: 21px; }
      &:before {
        width: 68px;
        height: 100%;
        background-position: center bottom;
        box-shadow: none;
        right: 70px; }
      &:after {
        width: 63px;
        height: 100%;
        background-position: center bottom;
        box-shadow: none;
        right: 27px; }
      .btn-accent {
        margin: 0 0 40px; } } }

  .additional-procedures__box {
    height: auto;
    flex-direction: column;
    margin: 0 auto 18px;
    .image {
      width: 100%;
      min-width: auto;
      height: 224px;
      min-height: 224px;
      border-radius: 10px 10px 0 0;
      img {
        height: auto;
        width: 100%;
        border-radius: 10px 10px 0 0; } }
    .text {
      h3 {
        font-size: 15px; } }
    .description {
      margin: 13px 0 20px; }
    .btn-accent {
      margin: 0;
      font-size: 12px; } }

  #modal__price {
    .modal__wrapper {
      .form {
        max-width: none;
        p {
          font-size: 11px;
          margin: 0 0 15px;
          text-align: center; } } } } }

@media only screen and (max-width : 580px) {
  h1, h2 {
    font-size: 30px;
    line-height: 30px; }

  .main-content .container p {
    font-size: 20px; }

  .equipment .border-accent {
    font-size: 13px;
    width: 113px;
    height: 24px;
    padding: 0; }

  .border-accent {
    font-size: 13px;
    width: 113px;
    height: 24px; }

  .price-table .price-table__body .cost p {
    font-size: 11px; }

  .price-table .price-table__body .cost, .price-table .price-table__header .cost {
    width: auto; }

  .about {
    .border-accent {
      font-size: 12px;
      margin: 12px auto 20px;
      padding: 5px 13px; } }

  .licenses-box {
    width: 100%;
    margin: 0 auto 22px;
    a {
      display: none; } }

  .info-contacts-company {
    .phone {
      font-size: 12px; }
    a {
      width: 60%; } }

  footer {
    .box-left {
      width: calc(100% - 70px); }
    .container {
      padding: 9px 19px 8px 25px;

      .logo {
        width: 66px; }
      .list-soc {
        li {
          a {
            max-width: 18px; } } } } }

  .menu {
    align-items: flex-end;
    li {
      a {
        font-size: 11px;
        text-align: right;
        line-height: normal; } } }

  .copyright, .politics, .web-developer {
    font-size: 9px; }

  .politics {
    margin: 4px 0; }

  .modal__div {
    width: 80%;
    .modal__wrapper {
      padding: 22px 32px 32px;
      .modal__close {
        width: 18px;
        height: 18px;
        padding: 3px; }
      h2 {
        font-size: 21px;
        line-height: 27px; }
      .form {
        margin: 49px 0 0;
        label {
          margin: 0 0 29px;
          input {
            font-size: 13px; } }
        button {
          margin: 50px auto 6px;
          width: 147px;
          height: 39px;
          font-size: 14px; } } } }

  .checkbox {
    .checkbox-custom {
      width: 8px;
      height: 8px; }
    .label {
      font-size: 6px; } }

  #modal__thanks {
    .modal__wrapper {
      .text {
        font-size: 11px;
        margin: 7px 0 10px;
        padding: 0 0 10px;
        &:before {
          width: 75px; } }
      p {
        font-size: 11px; }
      .list-soc {
        li {
          a {
            width: 10px;
            height: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 7px; } } } } }

  #modal__price {
    .modal__wrapper {
      .form {
        padding: 0 18px;
        box-sizing: border-box;
        label {
          input {
            font-size: 14px;
            width: 135px; } }
        button {
          width: 127px;
          height: 27px;
          font-size: 14px;
          margin: 0 0 0 auto; }
        .checkbox {
          width: 100%;
          justify-content: flex-end;
          text-align: right; } } } } }

/* Custom, iPhone Retina */
@media only screen and (max-width : 480px) {
  .box-contacts {
    &:before {
      display: none; }
    a {
      background: url("../img/phone.png") no-repeat center;
      width: 35px;
      height: 35px;
      border: 1px solid #ffffff;
      padding: 5px;
      box-sizing: border-box;
      border-radius: 10px; } }

  main {
    padding: 16px 0 65px;
    background: url("../img/poster.jpg") no-repeat center {
      size: cover; }
    .fullscreen-bg__video {
      display: none; } }

  .contacts {
    background: url("../img/bg1.jpg") no-repeat center {
      size: cover; }
    .fullscreen-bg__video {
      display: none; } }



  .main-content {
    margin: 75px 0;
    .container {
      .btn-white {
        margin: 39px 0 42px; } } }

  .equipment-text p {
    font-size: 14px; }

  input[name='phone'] {
    font-size: 14px; }

  .box-callback {
    .container {
      button {
        font-size: 14px; }
      .form {
        margin: 0; } } }

  .price-table .price-table__body {
    padding: 5px 10px; }

  .price-table {
    li {
      flex-wrap: nowrap; }
    .cost {
      margin: 0 auto; }
    .popular-zones {
      width: 106px; } }

  .list-instruction > li h3, .question-answer .list-instruction li h3 {
    font-size: 16px; }

  .list-instruction .b_hidden {
    padding: 12px 10px 0 0; }

  .list-instruction > li h3:after {
    top: 10px; }

  .list-instruction > li h3:before {
    top: 2px; }

  .list-instruction p {
    font-size: 14px;
    margin: 0;
    line-height: 16px; }

  .list-instruction ul li, .list-instruction p, .list-instruction strong {
    font-size: 14px;
    line-height: 19px; }

  .list-question li {
    padding: 0 0 0 20px; }

  .reviews-box {
    width: 90%;
    p {
      font-size: 14px;
      line-height: 18px; }
    .reviews-box__top {
      margin: 0 0 26px; } }


  .reviews-slider {
    padding: 0; }

  .additional-procedures__box {
    .image {
      img {
        height: 100%;
        width: auto;
        max-width: none; } } }

  .cosmetologists-box {
    padding: 25px 10px;
    .image {
      width: 136px;
      height: 136px; }
    h3 {
      font-size: 14px;
      font-weight: normal;
      margin: 15px 0; }
    ul {
      li {
        font-size: 13px;
        line-height: 14.24px; } } }

  #modal__price {
    .modal__wrapper {
      h2 {
        font-size: 14px; }
      .price-table {
        margin: 12px 0 0 0; } } }

  .box-callback {
    .container {
      p {
        font-size: 16px; } } } }

@media only screen and (max-width : 360px) {
  h1, h2 {
    font-size: 21px; }

  h1 {
    font-size: 26px; }

  .main-content {
    .container {
      p {
        font-size: 15px; } } }

  .btn-white {
    font-size: 16px;
    width: 184px;
    height: 38px; }

  .price-table .price-table__header div, .price-table .price-table__header .popular-zones, .price-table .price-table__header .first-visit p, .price-table .price-table__header .cost, .price-table .price-table__body div, .price-table .price-table__body .popular-zones, .price-table .price-table__body .first-visit p, .price-table .price-table__body .cost {
    font-size: 11px; }

  .price-table .price-table__header {
    padding: 0 5px 0 0; }

  .price-table .price-table__body .first-visit, .price-table .price-table__header .first-visit {
    min-width: 90px; } }

